var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ENDPOINTS, MIXI_HELPER_EVENTS } from "../../data/core-data";
import Wortal from "../../index";
import { generateUUID, getParameterByName, waitForPostMessageCallback } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a Mixi player.
 * @hidden
 */
export class MixiPlayer extends Player {
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const isMixiMobile = getParameterByName("oauth_consumer_key") == "mixi.jp";
            if (isMixiMobile) {
                Wortal.session._internalSession.setupForMobile = true;
            }
            let mixiPlayer = null;
            try {
                if (isMixiMobile) {
                    Wortal.session._internalSession.setupForMobile = true;
                    mixiPlayer = yield this._getMixiMobilePlayer();
                }
                else {
                    mixiPlayer = yield this._getMixiPlayer();
                }
            }
            catch (error) {
                Wortal._log.exception("Error initializing Mixi player: ", error);
            }
            this._data.id = ((_a = mixiPlayer === null || mixiPlayer === void 0 ? void 0 : mixiPlayer.id) === null || _a === void 0 ? void 0 : _a.toString()) || generateUUID();
            this._data.name = (mixiPlayer === null || mixiPlayer === void 0 ? void 0 : mixiPlayer.displayName) || "Player";
            this._data.photo = (mixiPlayer === null || mixiPlayer === void 0 ? void 0 : mixiPlayer.thumbnailUrl) || "https://storage.googleapis.com/html5gameportal.com/images/avatar.jpg";
            // Set DW account as test account, since there's no staging or production env
            const dwMixiId = "pgfap4iko6fjr";
            if (this._data.id == dwMixiId) {
                Wortal.session._internalSession.isSandbox = true;
            }
            Wortal._log.debug("Player initialized: ", this._data);
            return Promise.resolve();
        });
    }
    _getMixiPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                window.parent.postMessage({ action: MIXI_HELPER_EVENTS.GET_PROFILE }, '*');
                const handleProfileResponse = (event) => {
                    const { action, data } = event.data;
                    if (action === MIXI_HELPER_EVENTS.GET_PROFILE) {
                        const { id, displayName, thumbnailUrl } = data;
                        console.log('Profile Received:', id, displayName, thumbnailUrl);
                        return { id, displayName, thumbnailUrl };
                    }
                    if (action === MIXI_HELPER_EVENTS.GET_PROFILE_ERROR) {
                        console.error('Error fetching profile:', data.code);
                        throw new Error(`Error fetching profile: ${data.code}`);
                    }
                };
                const data = yield waitForPostMessageCallback(MIXI_HELPER_EVENTS.GET_PROFILE, handleProfileResponse);
                const mixiPlayer = {
                    id: data.id,
                    displayName: data.displayName,
                    thumbnailUrl: data.thumbnailUrl
                };
                return mixiPlayer;
            }
            catch (error) {
                Wortal._log.exception("Error fetching Mixi player: ", error);
                return Promise.reject(error);
            }
        });
    }
    _getMixiMobilePlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            const userId = getParameterByName("opensocial_viewer_id") || "";
            if (!userId) {
                throw new Error(`user id is not available`);
            }
            const url = `${API_ENDPOINTS.WORTAL_BASE_API}/mixi/profile/?user_id=${userId}&game=${window.wortalGameID}`;
            const resp = yield fetch(url, {
                method: 'GET',
            });
            if (!resp.ok) {
                throw new Error(`HTTP error! Status: ${resp.status}`);
            }
            const player = yield resp.json();
            return player;
        });
    }
}
