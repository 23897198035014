var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ENDPOINTS, RESOLUTIONS, SDK_SRC } from "../../data/core-data";
import { initializationError } from "../../errors/error-handler";
import Wortal from "../../index";
import { isValidString } from "../../utils/validators";
import { getParameterByName } from "../../utils/wortal-utils";
import { AdConfig } from "./ad-config";
/**
 * Wortal implementation of AdConfig. This is used for any platforms that rely on Wortal to serve ads, not only
 * the Wortal channel itself.
 *
 * This class can be inherited from to provide a base class for the ad stack on platforms
 * that will allow our stack in addition to others.
 * @hidden
 */
export class AdConfigWortal extends AdConfig {
    constructor() {
        super(...arguments);
        //#endregion
        //#region Ad Configs
        this._prebidConfig = {
            interstitial: {
                code: "",
                mediaTypes: {
                    banner: {
                        sizes: RESOLUTIONS,
                    }
                },
                bids: [],
            },
            rewarded: {
                code: "",
                mediaTypes: {
                    banner: {
                        sizes: RESOLUTIONS,
                    }
                },
                bids: [],
            },
            timeout: 3000,
            failSafeTimeout: 5000,
            isDebugMode: false,
            isEnabled: false,
        };
        this._prebidNoFillFallbackConfig = {
            interstitial: {
                code: "",
                mediaTypes: {
                    banner: {
                        sizes: RESOLUTIONS,
                    }
                },
                bids: [],
            },
            rewarded: {
                code: "",
                mediaTypes: {
                    banner: {
                        sizes: RESOLUTIONS,
                    }
                },
                bids: [],
            },
            timeout: 3000,
            failSafeTimeout: 5000,
            isDebugMode: false,
            isEnabled: false,
        };
        this._aquaConfig = {
            interstitial: {
                slotID: "INTERSTITIAL-AD",
                settings: {
                    id: "",
                    zoneId: "",
                }
            },
            rewarded: {
                slotID: "REWARDED-INTERSTITIAL-AD",
                settings: {
                    id: "",
                    zoneId: "",
                }
            },
            isEnabled: false,
        };
        this._afgConfig = {
            channelHostID: "",
            channelID: "",
            clientID: "",
            hostID: "",
            frequencyCap: "30s",
            pageURL: window.location.href,
            isDebugMode: false,
            isEnabled: false,
            source: "",
        };
        //#endregion
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            function waitTimeout(ms, errorMessage = 'timeout') {
                return new Promise((_, reject) => setTimeout(() => reject(new Error(errorMessage)), ms));
            }
            Wortal._log.debug("Ads: Initializing ad config..");
            const AD_SERVER_INIT_WAITING_TIMEOUT = 2000;
            try {
                yield Promise.race([
                    waitTimeout(AD_SERVER_INIT_WAITING_TIMEOUT),
                    this._initAdSetup()
                ]);
            }
            catch (error) {
                if (error.message === 'timeout') {
                    Wortal._log.info(`Ad server initialization took more than ${AD_SERVER_INIT_WAITING_TIMEOUT}ms, stop waiting for initialization.`);
                }
                else {
                    return Promise.reject(error);
                }
            }
        });
    }
    //#region Internal
    _internalPrebidConfig() {
        return this._prebidConfig;
    }
    _internalPrebidNoFillFallbackConfig() {
        return this._prebidNoFillFallbackConfig;
    }
    _internalAquaConfig() {
        return this._aquaConfig;
    }
    _internalAFGConfig() {
        return this._afgConfig;
    }
    //#endregion
    _initAdSetup() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this._afgConfig.source = getParameterByName("source") || "";
                yield this._getAdSettings();
                if (this._data.isEnabled) {
                    yield this._initializeAdSever();
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
            Wortal._log.debug("Ads: Ad config initialized.");
        });
    }
    _getAdSettings() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.status("Ads: Fetching ad units from Wortal API..");
            const startTime = performance.now();
            if (typeof window.wortalGameID === "undefined") {
                return Promise.reject(initializationError("Failed to retrieve wortalGameID. This may be due to an error when uploading the game bundle to Wortal.", "_getAdSettings"));
            }
            let url = `${API_ENDPOINTS.WORTAL_AD_CONFIG}${window.wortalGameID}/?platform=${Wortal._internalPlatform}`;
            if (this._afgConfig.source) {
                url = `${url}&source=${this._afgConfig.source}`;
            }
            try {
                const response = yield fetch(url);
                Wortal._log.debug("Ads: Fetching ad units from Wortal API response: ", response);
                if (!response.ok) {
                    return Promise.reject(initializationError("Failed to fetch ad settings from Wortal API.", "_getAdSettings"));
                }
                const adSettings = yield response.json();
                Wortal._log.debug("Ads: Fetching ad units from Wortal API response JSON: ", adSettings);
                if (adSettings.ad_free) {
                    this.setIsEnabled(false);
                    Wortal._log.debug("Ads: Ad free mode is On");
                    return adSettings;
                }
                if (adSettings.frequency_cap) {
                    this._afgConfig.frequencyCap = `${adSettings.frequency_cap}s`;
                    this._data.frequencyCapS = adSettings.frequency_cap;
                }
                // Wortal API response includes the intended orientation of the game.
                Wortal.session._internalSession.orientation = adSettings.orientation;
                // Sandbox mode
                const wortalAds = adSettings.ads.filter(ad => ad.code === "wortal");
                if (wortalAds.length > 0 && adSettings.ads.length === wortalAds.length) {
                    this._parseSandboxConfig(adSettings);
                    return adSettings;
                }
                // Production mode
                this._parsePrebidConfig(adSettings);
                const aquaAdSettings = adSettings.ads.filter(ad => ad.code === "aqua");
                aquaAdSettings.forEach(ad => {
                    this._parseAquaConfig(ad);
                });
                const afgAdSettings = adSettings.ads.filter(ad => ad.code === "afg");
                if (afgAdSettings.length > 0) {
                    this._parseAFGConfig(afgAdSettings[0]);
                }
                const inHouseAdSettings = adSettings.ads.filter(ad => ad.code === "inhouse");
                inHouseAdSettings.forEach(ad => {
                    this._parseInHouseConfig(ad);
                });
                const wortalFallbackAds = adSettings.ads.filter(ad => ad.code === "wortal");
                if (wortalFallbackAds.length > 0) {
                    this._parseNoFillFallbackConfig(adSettings);
                }
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                Wortal._log.performanceLog(`Ads fetched in ${executionTime}ms.`);
                // Return the ad settings so child classes can parse them if needed.
                return adSettings;
            }
            catch (error) {
                return Promise.reject(initializationError(error, "_getAdSettings"));
            }
        });
    }
    _initializeAdSever() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.internalCall("_initializeAdSever");
            const startTime = performance.now();
            if (this._prebidConfig.isDebugMode) {
                return this._initializeSandbox().then(() => {
                    const endTime = performance.now();
                    const executionTime = endTime - startTime;
                    Wortal._log.performanceLog(`Ads fetched in ${executionTime}ms.`);
                    Wortal._log.debug("Ads: Ad server initialized.");
                }).catch((error) => {
                    return Promise.reject(initializationError(error, "_initializeAdSever"));
                });
            }
            else {
                return Promise.all([
                    this._initializePrebid(),
                    this._initializeAqua(),
                    this._initializeAFG()
                ])
                    .then(() => {
                    return this.initializeInHouseAd();
                })
                    .then(() => {
                    const endTime = performance.now();
                    const executionTime = endTime - startTime;
                    Wortal._log.performanceLog(`Ads fetched in ${executionTime}ms.`);
                    Wortal._log.debug("Ads: Ad server initialized.");
                }).catch((error) => {
                    return Promise.reject(initializationError(error, "_initializeAdSever"));
                });
            }
        });
    }
    //#region Parsers
    _parsePrebidConfig(adSettings) {
        Wortal._log.internalCall("_parsePrebidConfig");
        this._prebidConfig.interstitial.code = this._aquaConfig.interstitial.slotID;
        this._prebidConfig.rewarded.code = this._aquaConfig.rewarded.slotID;
        // Parse the bidders and add them to the ad units.
        adSettings.ads.forEach(ad => {
            if (ad.code != "aqua" && ad.code != "afg" && ad.code != "wortal") {
                const bidderData = {
                    bidder: ad.code,
                    params: ad.settings
                };
                if (ad.ad_type == "interstitial") {
                    this._prebidConfig.interstitial.bids.push(bidderData);
                }
                else if (ad.ad_type == "rewarded") {
                    this._prebidConfig.rewarded.bids.push(bidderData);
                }
            }
        });
        Wortal._log.debug("Ads: Bidders parsed.", this._prebidConfig);
    }
    _parsePrebidNoFillFallbackConfig(adSettings) {
        Wortal._log.internalCall("_prebidNoFillFallbackConfig");
        this._prebidNoFillFallbackConfig.interstitial.code = this._aquaConfig.interstitial.slotID;
        this._prebidNoFillFallbackConfig.rewarded.code = this._aquaConfig.rewarded.slotID;
        // Parse the bidders and add them to the ad units.
        adSettings.ads.forEach(ad => {
            if (ad.code == "wortal") {
                const bidderData = {
                    bidder: ad.code,
                    params: ad.settings
                };
                if (ad.ad_type == "interstitial") {
                    this._prebidNoFillFallbackConfig.interstitial.bids.push(bidderData);
                }
                else if (ad.ad_type == "rewarded") {
                    this._prebidNoFillFallbackConfig.rewarded.bids.push(bidderData);
                }
            }
        });
        Wortal._log.debug("Ads: Fallback Bidders parsed.", this._prebidNoFillFallbackConfig);
    }
    _parseAquaConfig(adSettingsInstance) {
        Wortal._log.internalCall("_parseAquaConfig");
        // We need the ID and zoneID to initialize Aqua.
        const adSettings = adSettingsInstance.settings;
        if (typeof adSettings !== "object" || !("id" in adSettings) || !("zoneId" in adSettings)) {
            throw initializationError("Aqua ad settings are not valid.", "_parseAquaConfig");
        }
        if (!isValidString(adSettings.id)) {
            throw initializationError("Aqua ad ID is not valid.", "_parseAquaConfig");
        }
        if (!isValidString(adSettings.zoneId)) {
            throw initializationError("Aqua ad zone ID is not valid.", "_parseAquaConfig");
        }
        if (adSettingsInstance.ad_type == "interstitial") {
            this._aquaConfig.interstitial.settings.id = adSettings.id;
            this._aquaConfig.interstitial.settings.zoneId = adSettings.zoneId;
            Wortal._log.debug("Ads: Aqua interstitial config parsed.", this._aquaConfig.interstitial);
        }
        else if (adSettingsInstance.ad_type == "rewarded") {
            this._aquaConfig.rewarded.settings.id = adSettings.id;
            this._aquaConfig.rewarded.settings.zoneId = adSettings.zoneId;
            Wortal._log.debug("Ads: Aqua rewarded config parsed.", this._aquaConfig.rewarded);
        }
    }
    _parseAFGConfig(adSettingsInstance) {
        var _a, _b, _c, _d, _e;
        Wortal._log.internalCall("_parseAFGConfig");
        // We need the clientID to initialize AFG.
        const adSettings = adSettingsInstance.settings;
        if (typeof adSettings !== "object" || !("clientId" in adSettings)) {
            throw initializationError("AFG ad settings are not valid.", "_parseAFGConfig");
        }
        if (!isValidString(adSettings.clientId)) {
            throw initializationError("AFG client ID is not valid.", "_parseAFGConfig");
        }
        // We don't reject these because they are likely not present in the test environment.
        if (!isValidString(adSettings.channelId)) {
            Wortal._log.warn("Configuration \"channelid\" missing. Using default value. If testing in the Wortal dashboard than this can be safely ignored.");
        }
        if (!isValidString(adSettings.hostId)) {
            Wortal._log.warn("Configuration \"hostid\" missing. Using default value. If testing in the Wortal dashboard than this can be safely ignored.");
        }
        this._afgConfig.clientID = adSettings.clientId;
        this._afgConfig.channelID = (_a = adSettings.channelId) !== null && _a !== void 0 ? _a : "";
        this._afgConfig.hostID = (_b = adSettings.hostId) !== null && _b !== void 0 ? _b : "";
        this._afgConfig.channelHostID = (_c = adSettings.channelHostId) !== null && _c !== void 0 ? _c : "";
        this._afgConfig.pageURL = (_d = adSettings.pageUrl) !== null && _d !== void 0 ? _d : window.location.href;
        this._afgConfig.isDebugMode = (_e = adSettings.debug) !== null && _e !== void 0 ? _e : false;
        Wortal._log.debug("Ads: AFG config parsed.", this._afgConfig);
    }
    _parseSandboxConfig(adSettings) {
        Wortal._log.internalCall("_parseSandboxConfig");
        this._prebidConfig.isDebugMode = true;
        this._afgConfig.isDebugMode = true;
        adSettings.ads = adSettings.ads.filter(ad => ad.code === "wortal");
        this._parsePrebidNoFillFallbackConfig(adSettings);
        Wortal._log.debug("Ads: Sandbox mode enabled.");
    }
    _parseNoFillFallbackConfig(adSettings) {
        Wortal._log.internalCall("_parseFallbackConfig");
        adSettings.ads = adSettings.ads.filter(ad => ad.code === "wortal");
        this._parsePrebidNoFillFallbackConfig(adSettings);
        Wortal._log.debug("Ads: Fallback mode enabled.");
    }
    //#endregion
    //#region Initialization
    _initializePrebid() {
        Wortal._log.internalCall("_initializePrebid");
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = SDK_SRC.PREBID;
            script.onload = () => {
                window.pbjs = window.pbjs || {};
                window.pbjs.que = window.pbjs.que || [];
                this._prebidConfig.isEnabled = true;
                Wortal._log.debug("Ads: Prebid SDK loaded.");
                resolve();
            };
            script.onerror = () => {
                this._prebidConfig.isEnabled = false;
                resolve();
            };
            document.head.appendChild(script);
        });
    }
    _initializeAqua() {
        Wortal._log.internalCall("_initializeAqua");
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = SDK_SRC.AQUA_SEVER;
            script.onload = () => {
                this._aquaConfig.isEnabled = true;
                Wortal._log.debug("Ads: Aqua SDK loaded.");
                resolve();
            };
            script.onerror = () => {
                this._aquaConfig.isEnabled = false;
                resolve();
            };
            document.head.appendChild(script);
        });
    }
    _initializeAFG() {
        Wortal._log.internalCall("_initializeAFG");
        return new Promise((resolve) => {
            // Create the AFG script.
            const script = document.createElement("script");
            script.setAttribute("src", SDK_SRC.AFG);
            script.setAttribute("type", "text/javascript");
            // Set attributes based on AFG config.
            if (this._afgConfig.isDebugMode) {
                script.setAttribute("data-ad-client", "ca-pub-123456789");
                script.setAttribute("data-adbreak-test", "on");
            }
            else {
                script.setAttribute("data-ad-client", this._afgConfig.clientID);
                script.setAttribute("data-ad-frequency-hint", this._afgConfig.frequencyCap);
                script.setAttribute("data-page-url", this._afgConfig.pageURL);
                script.setAttribute("data-ad-channel", this._afgConfig.channelID);
                script.setAttribute("data-ad-host", this._afgConfig.hostID);
                // For AFP host.
                if (isValidString(this._afgConfig.hostID)) {
                    const tag = document.createElement("meta");
                    tag.setAttribute("name", "google-adsense-platform-account");
                    tag.setAttribute("content", this._afgConfig.hostID);
                    script.setAttribute("data-ad-host-channel", this._afgConfig.channelHostID);
                    document.head.appendChild(tag);
                }
            }
            script.onload = () => {
                // This is used by GPT to build ad requests.
                window.googletag = window.googletag || {};
                window.googletag.cmd = window.googletag.cmd || [];
                // This is the global function that the AdSense SDK uses to register ad breaks.
                // https://developers.google.com/ad-placement/docs/html5-game-structure
                window.adsbygoogle = window.adsbygoogle || [];
                window.adBreak = window.adConfig = function (o) {
                    window.adsbygoogle.push(o);
                };
                window.adConfig({ preloadAdBreaks: "on" });
                this._afgConfig.isEnabled = true;
                Wortal._log.debug("Ads: AFG SDK loaded.");
                resolve();
            };
            script.onerror = () => {
                this._afgConfig.isEnabled = false;
                Wortal._log.warn("Ad blocker detected. AFG SDK failed to load.");
                resolve();
            };
            document.head.appendChild(script);
        });
    }
    _initializeSandbox() {
        Wortal._log.internalCall("_initializeSandbox");
        return this._initializePrebid().then(() => {
            return this._initializePrebidNoFillFallback();
        }).then(() => {
            Wortal._log.debug("Ads: Sandbox initialized.");
        }).catch((error) => {
            throw initializationError(error, "_initializeSandbox");
        });
    }
    _initializePrebidNoFillFallback() {
        Wortal._log.internalCall("_initializePrebidNoFillFallback");
        if (!this._prebidConfig.isEnabled) {
            return Promise.all([this._initializePrebid()]).then(() => {
                Wortal._log.debug("Ads: Prebid no fill fallback initialized.");
                this._prebidNoFillFallbackConfig.isEnabled = true;
            }).catch((error) => {
                this._prebidNoFillFallbackConfig.isEnabled = false;
                throw initializationError(error, "_initializePrebidNoFillFallback");
            });
        }
        Wortal._log.debug("Ads: Prebid no fill fallback initialized.");
        this._prebidNoFillFallbackConfig.isEnabled = true;
        return Promise.resolve();
    }
}
