var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Wortal from "../..";
import { API_ENDPOINTS } from "../../data/core-data";
import { generateUUID, getParameterByName } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a Tsutaya player.
 * @hidden
 */
export class TsutayaPlayer extends Player {
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const isStaging = window.location.host == "stg.game-tsutaya.tsite.jp";
            if (isStaging) {
                Wortal.session._internalSession.isSandbox = true;
            }
            let player = null;
            try {
                player = yield this._getProfile();
            }
            catch (error) {
                Wortal._log.exception("Error initializing Tsutaya player: ", error);
            }
            this._data.id = ((_a = player === null || player === void 0 ? void 0 : player.id) === null || _a === void 0 ? void 0 : _a.toString()) || generateUUID();
            this._data.name = (player === null || player === void 0 ? void 0 : player.displayName) || "Player";
            this._data.photo = (player === null || player === void 0 ? void 0 : player.thumbnailUrl) || "https://storage.googleapis.com/html5gameportal.com/images/avatar.jpg";
            Wortal._log.debug("Player initialized: ", this._data);
            return Promise.resolve();
        });
    }
    _getProfile() {
        return __awaiter(this, void 0, void 0, function* () {
            const userId = getParameterByName("opensocial_viewer_id") || "";
            if (!userId) {
                throw new Error(`user id is not available`);
            }
            const isStaging = Wortal.session._internalSession.isSandbox;
            const url = `${API_ENDPOINTS.WORTAL_BASE_API}/tsutaya/profile/?user_id=${userId}&game=${window.wortalGameID}&is_test=${isStaging}`;
            const resp = yield fetch(url, {
                method: 'GET',
            });
            if (!resp.ok) {
                throw new Error(`HTTP error! Status: ${resp.status}`);
            }
            const player = yield resp.json();
            return player;
        });
    }
}
