import { initializationError } from "../../errors/error-handler";
import Wortal from "../../index";
import { generateUUID, getParameterByName, onAudioStatusChangeFunctions } from "../../utils/wortal-utils";
import country from "../../data/intl-data.json";
/**
 * Contains data about the current game session such as the platform, country, and game ID.
 * @hidden
 */
export class Session {
    constructor() {
        this._data = {
            sessionID: "",
            country: "",
            gameID: "",
            browser: "",
            timerID: "",
            timePlayed: 0,
            orientation: "portrait",
            legacyGameID: "",
            source: "",
            isSandbox: false,
            setupForMobile: false,
        };
        this._locale = "";
        this._isAudioEnabled = true;
        Wortal._log.status("Session: Initializing session...");
        this._data.sessionID = generateUUID();
        this._data.country = this._setCountry();
        this._data.gameID = this._setGameID();
        this._data.browser = navigator.userAgent;
        this._data.orientation = this._setOrientation();
        this._data.legacyGameID = window.legacyGameID || "";
        this._data.source = this._setSource();
        if (Wortal._internalPlatform === "gd" || Wortal._internalPlatform === "gamemonetize") {
            this._externalCallbacks = {};
        }
        this._startSessionTimer();
        Wortal._log.debug("Session: Session initialized: ", this._data);
    }
    get gameID() {
        return this._data.gameID;
    }
    get browser() {
        return this._data.browser;
    }
    get country() {
        return this._data.country;
    }
    get locale() {
        return this._locale;
    }
    set locale(locale) {
        this._locale = locale;
    }
    get sessionID() {
        return this._data.sessionID;
    }
    get timePlayed() {
        return this._data.timePlayed;
    }
    get orientation() {
        return this._data.orientation;
    }
    set orientation(orientation) {
        this._data.orientation = orientation;
    }
    get legacyGameID() {
        return this._data.legacyGameID;
    }
    get externalCallbacks() {
        return this._externalCallbacks;
    }
    get isAudioEnabled() {
        return this._isAudioEnabled;
    }
    get source() {
        return this._data.source;
    }
    get isSandbox() {
        return this._data.isSandbox;
    }
    set isSandbox(sandbox) {
        this._data.isSandbox = sandbox;
    }
    get setupForMobile() {
        return this._data.setupForMobile;
    }
    set setupForMobile(forMobile) {
        this._data.setupForMobile = forMobile;
    }
    set isAudioEnabled(isAudioEnabled) {
        this._isAudioEnabled = isAudioEnabled;
        onAudioStatusChangeFunctions.forEach((func) => func(isAudioEnabled));
    }
    _startSessionTimer() {
        this._data.timerID = generateUUID();
        setInterval(() => {
            if (document.visibilityState === "visible") {
                this._data.timePlayed += 1;
            }
        }, 1000);
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "hidden") {
                if (this._data.timePlayed > 10) {
                    // If the game end event was sent successfully, store the session data and mark it as sent.
                    // Otherwise, store the session data and mark it as queued to send on next play.
                    if (Wortal.analytics._logGameEnd()) {
                        Wortal.session._storeSessionData(true);
                    }
                    else {
                        Wortal.session._storeSessionData();
                    }
                    this._resetSessionTimer();
                }
            }
        });
    }
    _resetSessionTimer() {
        this._data.timerID = generateUUID();
        this._data.timePlayed = 0;
    }
    _setGameID() {
        const id = window.wortalGameID;
        if (id == undefined) {
            throw initializationError("Game ID is not defined.", "gameID");
        }
        Wortal._log.debug("Game ID: " + id);
        return id;
    }
    _setCountry() {
        // This isn't very reliable as the time zone can be easily changed, but we want a way to get the country
        // without using geolocation to avoid asking the user for permission or using an external service.
        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (typeof zone === "undefined") {
            Wortal._log.debug("Session: Could not get time zone, defaulting to unknown.");
            return "unknown";
        }
        const arr = zone.split("/");
        const city = arr[arr.length - 1];
        // @ts-ignore
        return country[city];
    }
    _setOrientation() {
        // We set the orientation based on the initial orientation of the device.
        // If using AdConfigWortal then we'll later update the orientation based on the settings provided by Wortal API.
        if (!screen || !screen.orientation || !screen.orientation.type) {
            Wortal._log.warn("Session: Could not get screen orientation, defaulting to portrait.");
            return "portrait";
        }
        const orientation = screen.orientation.type;
        if (orientation === "portrait-primary" || orientation === "portrait-secondary") {
            return "portrait";
        }
        else if (orientation === "landscape-primary" || orientation === "landscape-secondary") {
            return "landscape";
        }
        else {
            return "portrait";
        }
    }
    _setSource() {
        return getParameterByName("source") || "";
    }
}
