var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AdConfig } from "../ads/classes/ad-config";
import { AdConfigDebug } from "../ads/classes/ad-config-debug";
import { AdConfigFacebook } from "../ads/classes/ad-config-facebook";
import { AdConfigWortal } from "../ads/classes/ad-config-wortal";
import { AuthXsolla } from "../auth/impl/auth-xsolla";
import { API_ENDPOINTS, LOCAL_CHUNKS_ONLY } from "../data/core-data";
import { CrazyGamesPlayer } from "../player/classes/crazygames-player";
import { FacebookPlayer } from "../player/classes/facebook-player";
import { Player } from "../player/classes/player";
import { WortalPlayer } from "../player/classes/wortal-player";
import { YandexPlayer } from "../player/classes/yandex-player";
import { GameState } from "../session/classes/game-state";
import { Session } from "../session/classes/session";
import { initializationError } from "../errors/error-handler";
import { WortalLogger } from "../utils/logger";
import { addLoadingListener, addPauseResumeListener, delayUntilConditionMet } from "../utils/wortal-utils";
import { GMOPlayer } from "../player/classes/gmo-player";
import { KantanGamePlayer } from "../player/classes/kantangame-player";
import { MixiPlayer } from "../player/classes/mixi-player";
import { TsutayaPlayer } from "../player/classes/tsutaya-player";
/**
 * Core module for the SDK. This is the main entry point for the SDK. It is responsible for initializing the SDK
 * and loading the platform specific SDK. It also provides access to the other modules in the SDK. This should not
 * be access directly. Instead, use the global Wortal object.
 * @module Core
 */
export class CoreAPI {
    constructor() {
        //#region Private/Internal Members
        this._isInitialized = false;
        this._isAPIInitialized = false;
        this._isPlatformInitialized = false;
        this._platform = "debug";
        /**
         * Logger for the SDK. This is used to log messages to the console. Use this instead of console logging directly.
         * @internal
         */
        this._log = new WortalLogger();
        /**
         * Returns true if the SDK has been initialized.
         *
         * **No APIs should be called before the SDK has been initialized.**
         */
        this.isInitialized = false;
        //#endregion
    }
    /**
     * Holds a reference to the external SDK for the current platform. This is set in _initializePlatformAsync.
     * Some platforms, such as Telegram, do not require including an SDK so this will remain an empty object.
     *
     * See the platform specific SDK interface in core/interfaces for details on what APIs are available.
     *
     * **Do not attempt to access this before _initializePlatformAsync has finished and _internalIsPlatformInitialized is
     * true, as it will not be set.**
     * @internal
     */
    get _internalPlatformSDK() {
        return this._platformSDK;
    }
    /**
     * Sets the external SDK for the current platform. This should only ever be called in _initializePlatformAsync
     * after loading the platform specific SDK.
     * @internal
     */
    set _internalPlatformSDK(value) {
        this._platformSDK = value;
    }
    /**
     * Returns the current platform. This is set in _loadCoreAsync and should be used by the SDK to determine
     * which platform specific APIs to use.
     * @internal
     */
    get _internalPlatform() {
        return this._platform;
    }
    /**
     * Returns whether the API has been initialized. This is set in _loadAPIsAsync and should be used to determine
     * whether the platform specific APIs are ready to use.
     *
     * **Do not attempt to access _internalPlatformSDK before this is true, as it will not be set.**
     * @internal
     */
    get _internalIsAPIInitialized() {
        return this._isAPIInitialized;
    }
    /**
     * Returns whether the platform has been initialized. This is set in _initializePlatformAsync and should be used
     * to determine whether the platform specific APIs are ready to use.
     *
     * **Do not attempt to access _internalPlatformSDK before this is true, as it will not be set.**
     * @internal
     */
    get _internalIsPlatformInitialized() {
        return this._isPlatformInitialized;
    }
    /**
     * Returns the authentication API for the SDK.
     * @internal
     */
    get _internalAuth() {
        return this._core.auth;
    }
    /**
     * Initializes the SDK. This should be called before any other SDK functions. It is recommended to call this
     * as soon as the script has been loaded to shorten the initialization time.
     *
     * NOTE: This is only available if the manual initialization option is set to true. Otherwise, the SDK will initialize automatically.
     * @example
     * Wortal.initializeAsync().then(() => {
     *    // SDK is ready to use, wait for game to finish loading.
     *    Wortal.setLoadingProgress(100);
     *    Wortal.startGameAsync();
     * });
     * @returns {Promise<void>} Promise that resolves when the SDK initialized successfully.
     * @throws {ErrorMessage} See error.message for details.
     * <ul>
     * <li>INITIALIZATION_ERROR</li>
     * <li>NOT_SUPPORTED</li>
     * </ul>
     */
    initializeAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            // The developer may call this as soon as the page loads, but it will not be available until the core
            if (typeof this._core === "undefined") {
                yield delayUntilConditionMet(() => {
                    return typeof this._core !== "undefined";
                }, "Core: Core module not loaded. Waiting for it to load before calling initializeAsync...");
            }
            return this._core.initializeAsync();
        });
    }
    /**
     * This indicates that the game has finished initial loading and is ready to start. Context information will be
     * up-to-date when the returned promise resolves. The loading screen will be removed after this is called along with
     * the following conditions:
     * <ul>
     * <li>initializeAsync has been called and resolved</li>
     * <li>setLoadingProgress has been called with a value of 100</li>
     * </ul>
     *
     * NOTE: This is only available if the manual initialization option is set to true. Otherwise, the game will start automatically.
     * @example
     * Wortal.startGameAsync().then(() => {
     *    // Game is rendered to player.
     * });
     * @returns {Promise<void>} Promise that resolves when the game has started successfully.
     * @throws {ErrorMessage} See error.message for details.
     * <ul>
     * <li>INITIALIZATION_ERROR</li>
     * <li>NOT_SUPPORTED</li>
     * </ul>
     */
    startGameAsync() {
        return __awaiter(this, void 0, void 0, function* () {
            return this._core.startGameAsync();
        });
    }
    /**
     * Starts the authentication process for the player. If the current platform has its own authentication prompt then
     * this will be displayed.
     * @example
     * Wortal.authenticateAsync()
     * .then(response => console.log(response));
     * @param {AuthPayload} payload Optional payload for the authentication process.
     * @returns {Promise<AuthResponse>} Promise that resolves with the response from the authentication process.
     * @throws {ErrorMessage} See error.message for details.
     * <ul>
     * <li>AUTH_IN_PROGRESS</li>
     * <li>USER_ALREADY_AUTHENTICATED</li>
     * <li>USER_INPUT</li>
     * <li>NOT_SUPPORTED</li>
     * </ul>
     */
    authenticateAsync(payload) {
        return this._core.authenticateAsync(payload);
    }
    /**
     * Starts the account linking process for the player. If the current platform has its own account linking prompt then
     * this will be displayed.
     * @example
     * Wortal.linkAccountAsync()
     * .then(isLinked => console.log("Player linked account: " + isLinked));
     * @returns {Promise<boolean>} Promise that resolves when the player has finished the account linking process. The
     * promise will resolve with true if the player linked their account and false if they did not.
     * @throws {ErrorMessage} See error.message for details.
     * <ul>
     * <li>LINK_IN_PROGRESS</li>
     * <li>USER_NOT_AUTHENTICATED</li>
     * <li>NOT_SUPPORTED</li>
     * </ul>
     */
    linkAccountAsync() {
        return this._core.linkAccountAsync();
    }
    /**
     * Sets the loading progress value for the game. This is required for the game to start. Failure to call this with 100
     * once the game is fully loaded will prevent the game from starting.
     * @example
     * onGameLoadProgress(percent) {
     *     Wortal.setLoadingProgress(percent);
     * }
     *
     * onGameLoaded() {
     *     Wortal.setLoadingProgress(100);
     * }
     * @param value Percentage of loading complete. Range is 0 to 100.
     */
    setLoadingProgress(value) {
        this._core.setLoadingProgress(value);
    }
    /**
     * Sets a callback which will be invoked when the app is brought to the background.
     * @param callback Callback to invoke.
     */
    onPause(callback) {
        this._core.onPause(callback);
    }
    /**
     * Sets a callback which will be invoked when the app is brought to the foreground.
     * @param callback Callback to invoke.
     */
    onResume(callback) {
        this._core.onResume(callback);
    }
    /**
     * Requests and performs haptic feedback on supported devices.
     * @returns {Promise<void>} Haptic feedback requested successfully
     * @throws {ErrorMessage} See error.message for details.
     * <ul>
     * <li>NOT_SUPPORTED</li>
     * <li>CLIENT_UNSUPPORTED_OPERATION</li>
     * <li>INVALID_OPERATION</li>
     * </ul>
     */
    performHapticFeedbackAsync() {
        return this._core.performHapticFeedbackAsync();
    }
    /**
     * Gets the supported APIs for the current platform. Debug platform will return an empty array but supports all APIs.
     * @example
     * const supportedAPIs = Wortal.getSupportedAPIs();
     * if (supportedAPIs.includes("context.shareAsync")) {
     *    shareWithFriendsDialog.show();
     * }
     * @returns {string[]} Array of supported APIs.
     */
    getSupportedAPIs() {
        return this._core.getSupportedAPIs();
    }
    //#endregion
    //#region Internal API
    /**
     * Initializes the core module. This should be called as soon as the platform is known. This will import
     * and initialize the SDK for the given platform. Nothing should be done before this returns as the SDK
     * may need to perform some initialization before it can be used.
     * @param {InitializationOptions} options Initialization options. This can include options for debugging and testing.
     * @returns Promise that resolves when the core module has been initialized.
     * @internal
     * @hidden
     */
    _loadCoreAsync(options) {
        return __awaiter(this, void 0, void 0, function* () {
            this._log.internalCall("_loadCoreAsync");
            if (this._isInitialized) {
                return Promise.reject(initializationError("SDK already initialized.", "_loadCoreAsync"));
            }
            // Parse the initialization options first.
            this._log.info("Core: Initializing SDK " + __VERSION__);
            this._platform = options.platform;
            this._log.debug("Core: Platform: " + this._platform);
            // We might end the initialization process in several different places, we just listen for this event to know
            // when the SDK is finished initializing.
            const sdkStartTime = performance.now();
            window.addEventListener("wortal-sdk-initialized", () => {
                const sdkEndTime = performance.now();
                const sdkExecutionTime = sdkEndTime - sdkStartTime;
                this._log.performanceLog(`Wortal SDK initialized in ${sdkExecutionTime}ms.`);
                this._log.apiSuccess("initializeAsync", { executionTime: sdkExecutionTime });
            });
            // First we load the chunks we need for this platform. This includes wortal-common and the platform specific chunk.
            try {
                this._log.status("Core: Loading chunks...");
                const startTime = performance.now();
                yield this._loadChunksAsync(this._platform);
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                this._log.performanceLog(`Chunks loaded in ${executionTime}ms.`);
            }
            catch (error) {
                return Promise.reject(initializationError(`Failed to initialize SDK during loadChunksAsync: ${error.message}`, "_loadCoreAsync"));
            }
            // Next we dynamically import the API implementations for the current platform.
            try {
                this._log.status("Core: Loading APIs...");
                const startTime = performance.now();
                yield this._loadAPIsAsync(this._platform);
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                this._log.performanceLog(`APIs loaded in ${executionTime}ms.`);
                this._isAPIInitialized = true;
            }
            catch (error) {
                return Promise.reject(initializationError(`Failed to initialize SDK during loadAPIsAsync: ${error.message}`, "_loadCoreAsync"));
            }
            // Track the game loading time.
            this.session._gameLoadingStart();
            // Add the loading cover when the page loads, in case the body isn't loaded yet.
            addLoadingListener();
            // Track the game gaining/losing focus to trigger the onPause/onResume callbacks.
            addPauseResumeListener();
            // Now we can initialize the platform. This will initialize the platform's SDK any do any other initialization
            // required on the current platform.
            try {
                this._log.status("Core: Initializing platform...");
                const startTime = performance.now();
                yield this._core._initializePlatformAsync();
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                this._log.performanceLog(`Platform initialized in ${executionTime}ms.`);
            }
            catch (error) {
                return Promise.reject(initializationError(`Failed to initialize SDK during initializePlatformAsync: ${error.message}`, "_loadCoreAsync"));
            }
            // If the developer calls initializeAsync earlier than this, we need to wait for the platform to be initialized.
            // This flag will make initializeAsync await the platform initialization before continuing.
            this._isPlatformInitialized = true;
            // We've finished the internal initialization, now we wait for the developer to finish the initialization process.
            this._log.debug("Core: Platform initialization finished, awaiting manual initialization..");
            return Promise.resolve();
        });
    }
    _loadChunksAsync(platform) {
        return __awaiter(this, void 0, void 0, function* () {
            this._log.internalCall("_loadChunksAsync");
            // Some platforms impose CSP restrictions that prevent us from loading chunks from a CDN. In these cases
            // we need to load the chunks locally.
            for (const localOnlyPlatform of LOCAL_CHUNKS_ONLY) {
                if (platform === localOnlyPlatform) {
                    this._log.debug("Core: Loading chunks locally for platform: " + platform);
                    return Promise.resolve();
                }
            }
            let baseURL;
            if (__ENV__ === "development") {
                baseURL = API_ENDPOINTS.WORTAL_CHUNKS_DEV;
            }
            else {
                baseURL = API_ENDPOINTS.WORTAL_CHUNKS_PROD;
            }
            const chunks = [];
            const promises = [];
            // wortal-common and wortal-shared are always required.
            chunks.push("wortal-common.js");
            chunks.push("wortal-shared.js");
            chunks.push(`${platform}.js`);
            for (const chunk of chunks) {
                promises.push(new Promise((resolve, reject) => {
                    const script = document.createElement("script");
                    script.src = baseURL + chunk;
                    script.onload = () => {
                        resolve();
                    };
                    script.onerror = () => {
                        reject(initializationError(`Failed to load chunk: ${chunk}`, "_loadChunksAsync"));
                    };
                    document.head.appendChild(script);
                }));
            }
            return Promise.all(promises).then(() => {
                return;
            });
        });
    }
    // This is a big ugly mess. We should probably refactor this to be more elegant and less repetitive, but
    // we don't want to break the dynamic import and chunking, so we'll leave it for now until we have a
    // better idea of how to do it.
    //TODO: find a better way to do this without breaking the dynamic import and chunking
    _loadAPIsAsync(platform) {
        return __awaiter(this, void 0, void 0, function* () {
            this._log.internalCall("_loadAPIsAsync");
            // Use Wombat everywhere unless the platform forbids analytics.
            const { AnalyticsWombat } = yield import(/* webpackChunkName: "wortal-shared" */ "../analytics/impl/analytics-wombat");
            const { AnalyticsDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../analytics/impl/analytics-disabled");
            // Xsolla is used on multiple platforms.
            const { IAPXsolla } = yield import(/* webpackChunkName: "wortal-shared" */ "../iap/impl/iap-xsolla");
            // Disabled implementations for platforms that do not support some modules/features.
            const { AchievementsDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../achievements/impl/achievements-disabled");
            const { AuthDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../auth/impl/auth-disabled");
            const { ContextDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../context/impl/context-disabled");
            const { IAPDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../iap/impl/iap-disabled");
            const { LeaderboardDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../leaderboard/impl/leaderboard-disabled");
            const { NotificationsDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../notifications/impl/notifications-disabled");
            const { StatsDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../stats/impl/stats-disabled");
            const { TournamentDisabled } = yield import(/* webpackChunkName: "wortal-shared" */ "../tournament/impl/tournament-disabled");
            // Default implementations for platforms that do not require anything specific.
            const { PlayerDefault } = yield import(/* webpackChunkName: "wortal-shared" */ "../player/impl/player-default");
            const { SessionDefault } = yield import(/* webpackChunkName: "wortal-shared" */ "../session/impl/session-default");
            switch (platform) {
                case "addictinggames": {
                    const { CoreAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "./impl/core-addictinggames");
                    const { AchievementsAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "../achievements/impl/achievements-addictinggames");
                    const { AdsAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "../ads/impl/ads-addictinggames");
                    const { PlayerAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "../player/impl/player-addictinggames");
                    const { SessionAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "../session/impl/session-addictinggames");
                    const { StatsAddictingGames } = yield import(/* webpackChunkName: "addictinggames" */ "../stats/impl/stats-addictinggames");
                    this._core = new CoreAddictingGames(new AuthDisabled());
                    this.achievements = new AchievementsAddictingGames();
                    this.ads = new AdsAddictingGames(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerAddictingGames(new Player());
                    this.session = new SessionAddictingGames(new GameState(), new Session());
                    this.stats = new StatsAddictingGames();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "crazygames": {
                    const { CoreCrazyGames } = yield import(/* webpackChunkName: "crazygames" */ "./impl/core-crazygames");
                    const { AdsCrazyGames } = yield import(/* webpackChunkName: "crazygames" */ "../ads/impl/ads-crazygames");
                    const { ContextCrazyGames } = yield import(/* webpackChunkName: "crazygames" */ "../context/impl/context-crazygames");
                    const { IAPXsolla } = yield import(/* webpackChunkName: "crazygames" */ "../iap/impl/iap-xsolla");
                    const { PlayerCrazyGames } = yield import(/* webpackChunkName: "crazygames" */ "../player/impl/player-crazygames");
                    const { SessionCrazyGames } = yield import(/* webpackChunkName: "crazygames" */ "../session/impl/session-crazygames");
                    this._core = new CoreCrazyGames(new AuthXsolla());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsCrazyGames(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextCrazyGames();
                    this.iap = new IAPXsolla();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerCrazyGames(new CrazyGamesPlayer());
                    this.session = new SessionCrazyGames(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "facebook": {
                    const { CoreFacebook } = yield import(/* webpackChunkName: "facebook" */ "./impl/core-facebook");
                    const { AdsFacebook } = yield import(/* webpackChunkName: "facebook" */ "../ads/impl/ads-facebook");
                    const { AnalyticsFacebook } = yield import(/* webpackChunkName: "facebook" */ "../analytics/impl/analytics-facebook");
                    const { ContextFacebook } = yield import(/* webpackChunkName: "facebook" */ "../context/impl/context-facebook");
                    const { IAPFacebook } = yield import(/* webpackChunkName: "facebook" */ "../iap/impl/iap-facebook");
                    const { LeaderboardFacebook } = yield import(/* webpackChunkName: "facebook" */ "../leaderboard/impl/leaderboard-facebook");
                    const { NotificationsFacebook } = yield import(/* webpackChunkName: "facebook" */ "../notifications/impl/notifications-facebook");
                    const { PlayerFacebook } = yield import(/* webpackChunkName: "facebook" */ "../player/impl/player-facebook");
                    const { SessionFacebook } = yield import(/* webpackChunkName: "facebook" */ "../session/impl/session-facebook");
                    const { TournamentFacebook } = yield import(/* webpackChunkName: "facebook" */ "../tournament/impl/tournament-facebook");
                    this._core = new CoreFacebook(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsFacebook(new AdConfigFacebook());
                    this.analytics = new AnalyticsFacebook();
                    this.context = new ContextFacebook();
                    this.iap = new IAPFacebook();
                    this.leaderboard = new LeaderboardFacebook();
                    this.notifications = new NotificationsFacebook();
                    this.player = (new PlayerFacebook(new FacebookPlayer()));
                    this.session = new SessionFacebook(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentFacebook();
                    break;
                }
                case "gamemonetize": {
                    const { CoreGameMonetize } = yield import(/* webpackChunkName: "gamemonetize" */ "./impl/core-gamemonetize");
                    const { AdsGameMonetize } = yield import(/* webpackChunkName: "gamemonetize" */ "../ads/impl/ads-gamemonetize");
                    this._core = new CoreGameMonetize(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsGameMonetize(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerDefault(new Player());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "gamepix": {
                    const { CoreGamePix } = yield import(/* webpackChunkName: "gamepix" */ "./impl/core-gamepix");
                    const { AdsGamePix } = yield import(/* webpackChunkName: "gamepix" */ "../ads/impl/ads-gamepix");
                    const { SessionGamePix } = yield import(/* webpackChunkName: "gamepix" */ "../session/impl/session-gamepix");
                    const { StatsGamePix } = yield import(/* webpackChunkName: "gamepix" */ "../stats/impl/stats-gamepix");
                    this._core = new CoreGamePix(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsGamePix(new AdConfig());
                    this.analytics = new AnalyticsDisabled();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerDefault(new Player());
                    this.session = new SessionGamePix(new GameState(), new Session());
                    this.stats = new StatsGamePix();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "gamesnacks": {
                    const { CoreGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "./impl/core-gamesnacks");
                    const { AdsGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "../ads/impl/ads-gamesnacks");
                    const { AnalyticsGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "../analytics/impl/analytics-gamesnacks");
                    const { PlayerGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "../player/impl/player-gamesnacks");
                    const { SessionGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "../session/impl/session-gamesnacks");
                    const { StatsGameSnacks } = yield import(/* webpackChunkName: "gamesnacks" */ "../stats/impl/stats-gamesnacks");
                    this._core = new CoreGameSnacks(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsGameSnacks(new AdConfig());
                    this.analytics = new AnalyticsGameSnacks();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerGameSnacks(new Player());
                    this.session = new SessionGameSnacks(new GameState(), new Session());
                    this.stats = new StatsGameSnacks();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "gd": {
                    const { CoreGD } = yield import(/* webpackChunkName: "gd" */ "./impl/core-gd");
                    const { AdsGD } = yield import(/* webpackChunkName: "gd" */ "../ads/impl/ads-gd");
                    this._core = new CoreGD(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsGD(new AdConfig());
                    this.analytics = new AnalyticsDisabled();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerDefault(new Player());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "poki": {
                    const { CorePoki } = yield import(/* webpackChunkName: "poki" */ "./impl/core-poki");
                    const { AdsPoki } = yield import(/* webpackChunkName: "poki" */ "../ads/impl/ads-poki");
                    const { ContextPoki } = yield import(/* webpackChunkName: "poki" */ "../context/impl/context-poki");
                    const { SessionPoki } = yield import(/* webpackChunkName: "poki" */ "../session/impl/session-poki");
                    this._core = new CorePoki(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsPoki(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextPoki();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerDefault(new Player());
                    this.session = new SessionPoki(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "telegram": {
                    const { CoreTelegram } = yield import(/* webpackChunkName: "telegram" */ "./impl/core-telegram");
                    const { AdsTelegram } = yield import(/* webpackChunkName: "telegram" */ "../ads/impl/ads-telegram");
                    const { LeaderboardTelegram } = yield import(/* webpackChunkName: "telegram" */ "../leaderboard/impl/leaderboard-telegram");
                    const { PlayerTelegram } = yield import(/* webpackChunkName: "telegram" */ "../player/impl/player-telegram");
                    const { SessionTelegram } = yield import(/* webpackChunkName: "telegram" */ "../session/impl/session-telegram");
                    const { ContextTelegram } = yield import(/* webpackChunkName: "telegram" */ "../context/impl/context-telegram");
                    const { IAPTelegram } = yield import(/* webpackChunkName: "telegram" */ "../iap/impl/iap-telegram");
                    this._core = new CoreTelegram(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsTelegram(new AdConfigWortal());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextTelegram();
                    this.iap = new IAPTelegram();
                    this.leaderboard = new LeaderboardTelegram();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerTelegram(new Player()); // We can't include the TelegramPlayer here because it will block the demo project upload to FB.
                    this.session = new SessionTelegram(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "telegram-wortal": {
                    const { CoreTelegramWortal } = yield import(/* webpackChunkName: "telegram-wortal" */ "./impl/core-telegram-wortal");
                    const { AdsTelegramWortal } = yield import(/* webpackChunkName: "telegram-wortal" */ "../ads/impl/ads-telegram-wortal");
                    const { LeaderboardTelegramWortal } = yield import(/* webpackChunkName: "telegram-wortal" */ "../leaderboard/impl/leaderboard-telegram-wortal");
                    const { PlayerTelegramWortal } = yield import(/* webpackChunkName: "telegram-wortal" */ "../player/impl/player-telegram-wortal");
                    const { SessionTelegram } = yield import(/* webpackChunkName: "telegram-wortal" */ "../session/impl/session-telegram");
                    const { ContextTelegram } = yield import(/* webpackChunkName: "telegram-wortal" */ "../context/impl/context-telegram");
                    const { IAPTelegramWortal } = yield import(/* webpackChunkName: "telegram-wortal" */ "../iap/impl/iap-telegram-wortal");
                    this._core = new CoreTelegramWortal(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsTelegramWortal(new AdConfigWortal());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextTelegram();
                    this.iap = new IAPTelegramWortal();
                    this.leaderboard = new LeaderboardTelegramWortal();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerTelegramWortal(new Player()); // We can't include the TelegramPlayer here because it will block the demo project upload to FB.
                    this.session = new SessionTelegram(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "wortal": {
                    const { CoreWortal } = yield import(/* webpackChunkName: "wortal" */ "./impl/core-wortal");
                    const { AdsWortal } = yield import(/* webpackChunkName: "wortal" */ "../ads/impl/ads-wortal");
                    const { PlayerWortal } = yield import(/* webpackChunkName: "wortal" */ "../player/impl/player-wortal");
                    this._core = new CoreWortal(new AuthXsolla());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsWortal(new AdConfigWortal());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPXsolla();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerWortal(new WortalPlayer());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "wortal-native": {
                    const { CoreWortalNative } = yield import(/* webpackChunkName: "wortal-native" */ "./impl/core-wortal-native");
                    const { AdsWortalNative } = yield import(/* webpackChunkName: "wortal-native" */ "../ads/impl/ads-wortal-native");
                    this._core = new CoreWortalNative(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsWortalNative(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPXsolla();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerDefault(new Player());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "yandex": {
                    const { CoreYandex } = yield import(/* webpackChunkName: "yandex" */ "./impl/core-yandex");
                    const { AdsYandex } = yield import(/* webpackChunkName: "yandex" */ "../ads/impl/ads-yandex");
                    const { IAPYandex } = yield import(/* webpackChunkName: "yandex" */ "../iap/impl/iap-yandex");
                    const { LeaderboardYandex } = yield import(/* webpackChunkName: "yandex" */ "../leaderboard/impl/leaderboard-yandex");
                    const { PlayerYandex } = yield import(/* webpackChunkName: "yandex" */ "../player/impl/player-yandex");
                    this._core = new CoreYandex(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsYandex(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPYandex();
                    this.leaderboard = new LeaderboardYandex();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerYandex(new YandexPlayer());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "gmo": {
                    const { CoreGMO } = yield import(/* webpackChunkName: "gmo" */ "./impl/core-gmo");
                    const { AuthGMO } = yield import(/* webpackChunkName: "gmo" */ "../auth/impl/auth-gmo");
                    const { AdsDisabled } = yield import(/* webpackChunkName: "gmo" */ "../ads/impl/ads-disabled");
                    const { PlayerGMO } = yield import(/* webpackChunkName: "gmo" */ "../player/impl/player-gmo");
                    const { IAPGMO } = yield import(/* webpackChunkName: "gmo" */ "../iap/impl/iap-gmo");
                    this._core = new CoreGMO(new AuthGMO());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsDisabled(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPGMO();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerGMO(new GMOPlayer());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "kantangame": {
                    const { CoreKantanGame } = yield import(/* webpackChunkName: "kantangame" */ "./impl/core-kantangame");
                    const { AdsKantanGame } = yield import(/* webpackChunkName: "kantangame" */ "../ads/impl/ads-kantangame");
                    const { PlayerKantanGame } = yield import(/* webpackChunkName: "kantangame" */ "../player/impl/player-kantangame");
                    const { SessionKantanGame } = yield import(/* webpackChunkName: "kantangame" */ "../session/impl/session-kantangame");
                    const { StatsKantanGame } = yield import(/* webpackChunkName: "kantangame" */ "../stats/impl/stats-kantangame");
                    this._core = new CoreKantanGame(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsKantanGame(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPDisabled();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerKantanGame(new KantanGamePlayer());
                    this.session = new SessionKantanGame(new GameState(), new Session());
                    this.stats = new StatsKantanGame();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "mixi": {
                    const { CoreMixi } = yield import(/* webpackChunkName: "mixi" */ "./impl/core-mixi");
                    const { AdsDisabled } = yield import(/* webpackChunkName: "mixi" */ "../ads/impl/ads-disabled");
                    const { PlayerMixi } = yield import(/* webpackChunkName: "mixi" */ "../player/impl/player-mixi");
                    const { IAPMixi } = yield import(/* webpackChunkName: "mixi" */ "../iap/impl/iap-mixi");
                    const { SessionMixi } = yield import(/* webpackChunkName: "mixi" */ "../session/impl/session-mixi");
                    this._core = new CoreMixi(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsDisabled(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPMixi();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerMixi(new MixiPlayer());
                    this.session = new SessionMixi(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "tsutaya": {
                    const { CoreTsutaya } = yield import(/* webpackChunkName: "tsutaya" */ "./impl/core-tsutaya");
                    const { AdsDisabled } = yield import(/* webpackChunkName: "tsutaya" */ "../ads/impl/ads-disabled");
                    const { PlayerTsutaya } = yield import(/* webpackChunkName: "tsutaya" */ "../player/impl/player-tsutaya");
                    const { IAPTsutaya } = yield import(/* webpackChunkName: "tsutaya" */ "../iap/impl/iap-tsutaya");
                    this._core = new CoreTsutaya(new AuthDisabled());
                    this.achievements = new AchievementsDisabled();
                    this.ads = new AdsDisabled(new AdConfig());
                    this.analytics = new AnalyticsWombat();
                    this.context = new ContextDisabled();
                    this.iap = new IAPTsutaya();
                    this.leaderboard = new LeaderboardDisabled();
                    this.notifications = new NotificationsDisabled();
                    this.player = new PlayerTsutaya(new TsutayaPlayer());
                    this.session = new SessionDefault(new GameState(), new Session());
                    this.stats = new StatsDisabled();
                    this.tournament = new TournamentDisabled();
                    break;
                }
                case "debug": {
                    const { CoreDebug } = yield import(/* webpackChunkName: "debug" */ "./impl/core-debug");
                    const { AchievementsDebug } = yield import(/* webpackChunkName: "debug" */ "../achievements/impl/achievements-debug");
                    const { AdsDebug } = yield import(/* webpackChunkName: "debug" */ "../ads/impl/ads-debug");
                    const { ContextDebug } = yield import(/* webpackChunkName: "debug" */ "../context/impl/context-debug");
                    const { IAPDebug } = yield import(/* webpackChunkName: "debug" */ "../iap/impl/iap-debug");
                    const { LeaderboardDebug } = yield import(/* webpackChunkName: "debug" */ "../leaderboard/impl/leaderboard-debug");
                    const { NotificationsDebug } = yield import(/* webpackChunkName: "debug" */ "../notifications/impl/notifications-debug");
                    const { PlayerDebug } = yield import(/* webpackChunkName: "debug" */ "../player/impl/player-debug");
                    const { SessionDebug } = yield import(/* webpackChunkName: "debug" */ "../session/impl/session-debug");
                    const { StatsDebug } = yield import(/* webpackChunkName: "debug" */ "../stats/impl/stats-debug");
                    const { TournamentDebug } = yield import(/* webpackChunkName: "debug" */ "../tournament/impl/tournament-debug");
                    this._core = new CoreDebug(new AuthDisabled());
                    this.achievements = new AchievementsDebug();
                    this.ads = new AdsDebug(new AdConfigDebug());
                    this.analytics = new AnalyticsDisabled();
                    this.context = new ContextDebug();
                    this.iap = new IAPDebug();
                    this.leaderboard = new LeaderboardDebug();
                    this.notifications = new NotificationsDebug();
                    this.player = new PlayerDebug(new Player());
                    this.session = new SessionDebug(new GameState(), new Session());
                    this.stats = new StatsDebug();
                    this.tournament = new TournamentDebug();
                    break;
                }
                default:
                    return Promise.reject(initializationError(`Unsupported platform: ${platform}`, "_loadCoreAsync"));
            }
        });
    }
}
