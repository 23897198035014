var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_ENDPOINTS, SDK_SRC } from "../../data/core-data";
import { initializationError } from "../../errors/error-handler";
import Wortal from "../../index";
import { isValidString } from "../../utils/validators";
/**
 * Config for the Ads API. Holds ad unit IDs, tracks ad calls and ad impressions.
 * @hidden
 */
export class AdConfig {
    constructor() {
        this._data = {
            isAdBlocked: false,
            hasPrerollShown: false,
            interstitialId: "",
            rewardedId: "",
            bannerId: "",
            adsCalled: 0,
            adsShown: 0,
            frequencyCapS: 30,
            lastAdCallTimeMS: 0,
            isEnabled: true,
            inHouseAdConfig: {
                interstitial: {
                    slotID: "INTERSTITIAL-VIDEO",
                    settings: {
                        id: "",
                        zoneId: "",
                    }
                },
                rewarded: {
                    slotID: "REWARDED-VIDEO",
                    settings: {
                        id: "",
                        zoneId: "",
                    }
                },
                isEnabled: false,
            }
        };
    }
    /**
     * Initializes the AdConfig. This fetches ad unit IDs from a remote source if necessary.
     * @returns {Promise<void>} Promise that resolves when the AdConfig is initialized.
     * @hidden
     */
    initialize() {
        Wortal._log.debug("Initializing AdConfig..");
        Wortal._log.debug("AdConfig initialized.", this._data);
        return Promise.resolve();
    }
    get isAdBlocked() {
        return this._data.isAdBlocked;
    }
    setAdBlocked(isBlocked) {
        this._data.isAdBlocked = isBlocked;
    }
    get isEnabled() {
        return this._data.isEnabled;
    }
    setIsEnabled(isEnabled) {
        this._data.isEnabled = isEnabled;
    }
    get hasPrerollShown() {
        return this._data.hasPrerollShown;
    }
    setPrerollShown(hasShown) {
        this._data.hasPrerollShown = hasShown;
    }
    get interstitialId() {
        return this._data.interstitialId;
    }
    get rewardedId() {
        return this._data.rewardedId;
    }
    get bannerId() {
        return this._data.bannerId;
    }
    get adsCalled() {
        return this._data.adsCalled;
    }
    adCalled() {
        this._data.adsCalled++;
    }
    get adsShown() {
        return this._data.adsShown;
    }
    get inHouseAdConfig() {
        return this._data.inHouseAdConfig;
    }
    get inHouseAdEnabled() {
        return this._data.inHouseAdConfig.isEnabled;
    }
    setfrequencyCapS(frequencyCapInSecond) {
        this._data.frequencyCapS = frequencyCapInSecond;
    }
    setInHouseAdEnabled(isEnabled) {
        this._data.inHouseAdConfig.isEnabled = isEnabled;
    }
    adShown() {
        this._data.adsShown++;
    }
    isFrequencyCapped() {
        const frequencyCapMS = this._data.frequencyCapS * 1000;
        const currentTimeMS = new Date().getTime();
        const timeSinceLastAdMS = currentTimeMS - this._data.lastAdCallTimeMS;
        return timeSinceLastAdMS < frequencyCapMS;
    }
    resetFrequencyCap() {
        this._data.lastAdCallTimeMS = new Date().getTime();
    }
    initializeInHouseAd() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.internalCall("initializeInHouseAd");
            const startTime = performance.now();
            return this._initializeRMPPlayer()
                .then(() => {
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                Wortal._log.performanceLog(`In house Ads initialized in ${executionTime}ms.`);
                Wortal._log.debug("Ads: In house Ads initialized.");
            }).catch((error) => {
                return Promise.reject(initializationError(error, "initializeInHouseAd"));
            });
        });
    }
    getInHouseAdSetting() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.status("Ads: Fetching in house ad units from Wortal API..");
            const startTime = performance.now();
            if (typeof window.wortalGameID === "undefined") {
                return Promise.reject(initializationError("Failed to retrieve wortalGameID. This may be due to an error when uploading the game bundle to Wortal.", "getInHouseAdSetting"));
            }
            let url = `${API_ENDPOINTS.WORTAL_AD_CONFIG}${window.wortalGameID}/?platform=${Wortal._internalPlatform}&inhousead=true`;
            try {
                const response = yield fetch(url);
                Wortal._log.debug("Ads: Fetching in house ad units from Wortal API response: ", response);
                if (!response.ok) {
                    return Promise.reject(initializationError("Failed to fetch ad settings from Wortal API.", "getInHouseAdSetting"));
                }
                const adSettings = yield response.json();
                Wortal._log.debug("Ads: Fetching ad units from Wortal API response JSON: ", adSettings);
                const endTime = performance.now();
                const executionTime = endTime - startTime;
                Wortal._log.performanceLog(`Ads fetched in ${executionTime}ms.`);
                const aquaAdSettings = adSettings.ads.filter(ad => ad.code === "inhouse");
                aquaAdSettings.forEach(ad => {
                    this._parseInHouseConfig(ad);
                });
                // Return the ad settings so child classes can parse them if needed.
                return adSettings;
            }
            catch (error) {
                return Promise.reject(initializationError(error, "getInHouseAdSetting"));
            }
        });
    }
    _parseInHouseConfig(adSettingsInstance) {
        Wortal._log.internalCall("_parseInHouseConfig");
        // We need the ID and zoneID to initialize Aqua (InHouse ad).
        const adSettings = adSettingsInstance.settings;
        if (typeof adSettings !== "object" || !("id" in adSettings) || !("zoneId" in adSettings)) {
            throw initializationError("inhouse ad settings are not valid.", "_parseInHouseConfig");
        }
        if (!isValidString(adSettings.id)) {
            throw initializationError("inhouse ad ID is not valid.", "_parseInHouseConfig");
        }
        if (!isValidString(adSettings.zoneId)) {
            throw initializationError("inhouse ad zone ID is not valid.", "_parseInHouseConfig");
        }
        if (adSettingsInstance.ad_type == "interstitial") {
            this._data.inHouseAdConfig.interstitial.settings.id = adSettings.id;
            this._data.inHouseAdConfig.interstitial.settings.zoneId = adSettings.zoneId;
            Wortal._log.debug("Ads: inhouse interstitial config parsed.", this._data.inHouseAdConfig.interstitial);
        }
        else if (adSettingsInstance.ad_type == "rewarded") {
            this._data.inHouseAdConfig.rewarded.settings.id = adSettings.id;
            this._data.inHouseAdConfig.rewarded.settings.zoneId = adSettings.zoneId;
            Wortal._log.debug("Ads: inhouse rewarded config parsed.", this._data.inHouseAdConfig.rewarded);
        }
    }
    _initializeRMPPlayer() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.internalCall("_initializeAqua");
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = SDK_SRC.RMP_SDK;
                script.onload = () => {
                    this._data.inHouseAdConfig.isEnabled = true;
                    Wortal._log.debug("Ads: Aqua SDK loaded.");
                    resolve();
                };
                script.onerror = () => {
                    this._data.inHouseAdConfig.isEnabled = false;
                    resolve();
                };
                document.head.appendChild(script);
            });
        });
    }
}
