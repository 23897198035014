var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Wortal from "../..";
import { generateUUID } from "../../utils/wortal-utils";
import { Player } from "./player";
/**
 * Represents a GMO player.
 * @hidden
 */
export class GMOPlayer extends Player {
    constructor(player) {
        super();
        let id = localStorage.getItem("gmo_generated_player_id");
        if (!id) {
            id = generateUUID();
            localStorage.setItem("gmo_generated_player_id", id);
        }
        this._data.id = (player === null || player === void 0 ? void 0 : player.id) || id;
        this._data.name = (player === null || player === void 0 ? void 0 : player.displayName) || "Player";
        this._data.photo = (player === null || player === void 0 ? void 0 : player.thumbnailUrl) || "https://storage.googleapis.com/html5gameportal.com/images/avatar.jpg";
    }
    initialize() {
        return __awaiter(this, void 0, void 0, function* () {
            Wortal._log.debug("Player initialized: ", this._data);
            Wortal._log.debug("Player initialized: ", this._data);
            return Promise.resolve(undefined);
        });
    }
}
